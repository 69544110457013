
var BergamoCreations= "https://bergamocreations.com/"

const Footer = () =>
  <div className="footer">
    <p>
        Website by <a href={BergamoCreations}
        className="footer-a"target="_blank" rel="noreferrer">Bergamo Creations</a> <br/>
    © 2023 Janne Avara
    </p>
  </div>


export default Footer;
